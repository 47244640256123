import React, { useState } from "react";
import Card from "@components/Common/Card";
import Image from "@components/Common/CloudinaryImage";

const MeCard = () => {
    const [showMe, setShowMe] = useState(false);

    return (
        <>
            <Card
                name="meCard"
                className="bg-indigo-400 rounded-2xl shadow-xl overflow-hidden"
            >
                <Image filename="bgs/emma-alex" className="bg-cover bg-center">
                    <button
                        onClick={() => {
                            setShowMe(!showMe);
                        }}
                        className={`bg-indigo-900 duration-500  h-full flex ${
                            !showMe ? "bg-opacity-90" : "bg-opacity-25"
                        }`}
                    >
                        <div
                            className={`mx-auto my-auto py-20 px-10 lg:py-36 lg:px-20 text-white duration-500 ${
                                showMe ? "opacity-0" : "opacity-100"
                            }`}
                        >
                            <h1 className="text-4xl leading-snug md:leading-normal md:text-5xl lg:text-6xl font-extrabold opacity-80">
                                Hola, me llamo Àlex
                                <span className="text-red-500">.</span>
                            </h1>
                            <h2 className="text-justify mt-10 leading-7 opacity-80">
                                Mi nombre es Àlex Llaó y nací hace ya{" "}
                                {new Date().getFullYear() - 1981} años en
                                Tarragona. Soy Ingeniero Técnico en Informática
                                de Gestión e Ingeniero en Informática por la
                                Universidad Rovira i Virgili (URV), también
                                situada en Tarragona.
                                <br />
                                <br />
                                Actualmente resido en l'Ampolla, el Baix Ebre
                                (Tarragona).
                            </h2>
                        </div>
                    </button>
                </Image>
            </Card>
        </>
    );
};
export default MeCard;
