import React, { useRef, useEffect, useState, useContext } from "react";
import { HeaderContext } from "@base/contexts/HeaderContext";
import "@base/styles/Card.css";

const Card = ({ children, name, className, title }) => {
    const cardRef = useRef(null);
    const [, setIntersection] = useState(false);
    const { setSection } = useContext(HeaderContext);

    useEffect(() => {
        const handleIntersection = function (entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIntersection(true);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.25,
        });

        observer.observe(cardRef.current);
        return () => observer.disconnect();
    }, [cardRef, setSection]);

    return (
        <>
            <div id={name} name={name} ref={cardRef}>
                <div className={`${className}`}>
                    <CardTitle title={title} />
                    {children}
                </div>
            </div>
        </>
    );
};

const CardTitle = ({ title }) => {
    return (
        <>
            {title && (
                <div>
                    <h3 className="font-extrabold text-xl mb-2">{title}</h3>
                    <div className="border-t-4 border-red-500 w-6 rounded-full mb-20"></div>
                </div>
            )}
        </>
    );
};

export default Card;
