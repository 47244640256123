import React from "react";
import Header from "@components/Pages/Header";
import Footer from "@components/Pages/Footer";
import BackToTop from "@components/Pages/BackToTop";
import "@base/styles/Layout.css";

const Layout = ({ children }) => {
    return (
        <>
            <div className="px-5 py-5 lg:px-0 lg:py-10">
                <div className="max-w-6xl mx-auto lg:flex lg:gap-10">
                    <Header />
                    {/** Cards Content */}
                    <div className="lg:w-3/4 grid grid-cols-1 gap-10">
                        {children}
                        <Footer />
                    </div>
                </div>
                <BackToTop />
            </div>
        </>
    );
};

export default Layout;
