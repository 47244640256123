import React from "react";
import Layout from "@base/layouts/Layout";
import Seo from "@components/Common/Seo";

import MeCard from "../components/Cards/MeCard";
import AboutCard from "../components/Cards/LazyAboutCard";
import SkillsCard from "../components/Cards/LazySkillsCard";
import ResumeCard from "../components/Cards/ResumeCard";
import WorksCard from "../components/Cards/WorksCard";
import ContactCard from "../components/Cards/LazyContactCard";

export default function Home() {
    return (
        <>
            <Seo title="Inicio" />
            <Layout>
                <MeCard />
                <AboutCard />
                <SkillsCard />
                <ResumeCard />
                <WorksCard />
                <ContactCard />
            </Layout>
        </>
    );
}
