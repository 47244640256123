import React from "react";
import loadable from "@loadable/component";
import { Transition } from "@headlessui/react";
import useNearScreen from "@base/hooks/useNearScreen";

const AboutCard = loadable(() => import("../Cards/AboutCard"));

const LazyAboutCard = () => {
    const { isNearScreen, fromRef } = useNearScreen();
    return (
        <>
            <div ref={fromRef}>
                {isNearScreen ? (
                    <Transition
                        className="col-span-2"
                        show={isNearScreen}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <AboutCard />
                    </Transition>
                ) : null}
            </div>
        </>
    );
};

export default LazyAboutCard;
