import React from "react";
import Card from "@components/Common/Card";

const ResumeCard = () => {
    return (
        <>
            <Card
                title="Currículum"
                name="resumeCard"
                className="bg-white rounded-2xl shadow-xl px-10 py-20"
            >
                <div className="grid grid-cols-1 gap-10">
                    {/** Experiencia */}
                    <div className="pt-5">
                        <div className="border-l-4 border-indigo-600 pl-3 ">
                            <h4 className="font-semibold uppercase text-sm text-gray-500">
                                Experiencia
                            </h4>
                        </div>
                        <div className="mt-5">
                            <ul className="ml-5 text-sm text-gray-500">
                                <li className="list-decimal mb-5">
                                    <h5 className="text-gray-600 font-semibold">
                                        Creasoft (10/2005 - 05/2006)
                                    </h5>
                                    <div className="text-xs text-gray-500">
                                        <div className="italic text-gray-500 mt-1">
                                            Programador de aplicaciones Visión
                                            por Computador
                                        </div>
                                        <div className="text-justify mt-2 leading-5">
                                            Programador de aplicaciones de
                                            Visión por Computador destinadas a
                                            la industria automovilística, usando
                                            Visual C++, las librerías gráficas
                                            OpenCV y las cámaras lineales
                                            Coreco.
                                        </div>
                                    </div>
                                </li>
                                <li className="list-decimal mb-5">
                                    <h5 className="text-gray-600 font-semibold">
                                        Ayuntamiento de l'Ametlla de Mar
                                        (08/2006 - Presente)
                                    </h5>
                                    <div className="text-xs text-gray-500">
                                        <div className="italic text-gray-500 mt-1">
                                            Administrador de sistemas,
                                            mantenimiento de hardware y
                                            desarrollo de software
                                        </div>
                                        <div className="text-justify mt-2 leading-5">
                                            Instalación de servidores,
                                            configuración de Firewalls, Access
                                            Points, VPN's, Virtualización,
                                            automatización de tareas, etc.
                                        </div>
                                    </div>
                                </li>
                                <li className="list-decimal mb-5">
                                    <h5 className="text-gray-600 font-semibold">
                                        MOYBU, SCP (01/2011 - Presente)
                                    </h5>
                                    <div className="text-xs text-gray-400 mt-1">
                                        <div className="italic text-gray-500 mt-1">
                                            Programador Full-Stack
                                        </div>
                                        <div className="text-justify mt-2 leading-5 text-gray-500">
                                            En 2011 fundé, junto a mi hermano,
                                            la empresa de desarrollo de software
                                            MOYBU. Desarrollamos aplicaciones
                                            para terminales móviles, backends,
                                            etc.
                                        </div>
                                    </div>
                                </li>
                                <li className="list-decimal">
                                    <h5 className="text-gray-600 font-semibold">
                                        Ayuntamiento de l'Ampolla (05/2012 -
                                        12/2019)
                                    </h5>
                                    <div className="text-xs text-gray-400 mt-1">
                                        <div className="italic text-gray-500 mt-1">
                                            Administrador de sistemas y
                                            mantenimiento de hardware
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/** Formación */}
                    <div className="pt-5">
                        <div className="border-l-4 border-indigo-600 pl-3 ">
                            <h4 className="font-semibold uppercase text-sm text-gray-500">
                                Formación / Educación
                            </h4>
                        </div>
                        <div className="mt-5">
                            <ul className="ml-5 text-sm text-gray-500">
                                <li className="mb-5">
                                    <h5 className="text-gray-600 font-semibold">
                                        Ingeniería en Informática
                                    </h5>
                                    <div className="text-xs text-gray-400 mt-1">
                                        <div className="italic text-gray-500 mt-1">
                                            Universidad Rovira i Virgili
                                        </div>
                                        <div className="mt-1 text-gray-500">
                                            2003 - 2006
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h5 className="text-gray-600 font-semibold">
                                        Ingeniería Técnica en Informática de
                                        Gestión
                                    </h5>
                                    <div className="text-xs text-gray-400 mt-1">
                                        <div className="italic text-gray-500 mt-1">
                                            Universidad Rovira i Virgili
                                        </div>
                                        <div className="mt-1 text-gray-500">
                                            1999 - 2003
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/** Habilidades */}
                    <div className="pt-5">
                        <div className="border-l-4 border-indigo-600 pl-3 ">
                            <h4 className="font-semibold uppercase text-sm text-gray-500">
                                Habilidades
                            </h4>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-5">
                            <div className="ml-5">
                                <h5 className="text-sm">Técnicas</h5>
                                <ul className="list-disc ml-5 text-xs text-gray-500 mt-2">
                                    <li className="mb-2">
                                        ASP.NET C#, NodeJS, NextJS
                                    </li>
                                    <li className="mb-2">Javascript, React</li>
                                    <li className="mb-2">
                                        MongoDB, MySQL, SQLServer
                                    </li>
                                    <li className="mb-2">Objective C, Swift</li>
                                    <li className="mb-2">
                                        Visual Studio, XCode, VSCode
                                    </li>
                                    <li className="mb-2">HTML, CSS3</li>
                                    <li className="mb-2">
                                        Docker, Hyper-V, VMWare
                                    </li>
                                    <li>Github, BitBucket</li>
                                </ul>
                            </div>
                            <div className="ml-5 md:ml-0">
                                <h5 className="text-sm">Profesionales</h5>
                                <ul className="list-disc ml-5 text-xs text-gray-500 mt-2">
                                    <li className="mb-2">Fácil comunicación</li>
                                    <li className="mb-2">Proactivo</li>
                                    <li className="mb-2">
                                        Capacidad para el aprendizaje de nuevos
                                        conceptos y metodologías
                                    </li>
                                    <li>Facilidad para el trabajo en grupo</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/** Idiomas */}
                    <div className="pt-5">
                        <div className="">
                            <div className="border-l-4 border-indigo-600 pl-3 ">
                                <h4 className="font-semibold uppercase text-sm text-gray-500">
                                    Idiomas
                                </h4>
                            </div>
                            <div className="mt-5">
                                <ul className="ml-5 text-xs text-gray-500 flex flex-wrap gap-10">
                                    <li className="mb-2">
                                        <h5 className="text-gray-600 font-semibold text-sm">
                                            Catalán
                                        </h5>
                                        <p className="italic mt-2">Nativo</p>
                                    </li>
                                    <li className="mb-2">
                                        <h5 className="text-gray-600 font-semibold text-sm">
                                            Castellano / Español
                                        </h5>
                                        <p className="italic mt-2">Nativo</p>
                                    </li>
                                    <li className="mb-2">
                                        <h5 className="text-gray-600 font-semibold text-sm">
                                            Inglés
                                        </h5>
                                        <p className="italic mt-2">
                                            Technical Reading: Alto
                                        </p>
                                        <p className="italic mt-1">
                                            Speaking: Medio - Bajo
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/** Premios */}
                    <div className="pt-5">
                        <div className="border-l-4 border-indigo-600 pl-3 ">
                            <h4 className="font-semibold uppercase text-sm text-gray-500">
                                Premios / Menciones
                            </h4>
                        </div>
                        <div className="mt-5">
                            <ul className="ml-5 text-xs text-gray-500 flex justify-start gap-10">
                                <li className="">
                                    <h5 className="text-gray-600 font-semibold text-sm">
                                        Premio Ruralapps Ciutadania de
                                        Generalitat de Catalunya
                                    </h5>
                                    <p className="italic mt-2">
                                        Ganadores Premio Aplicación
                                        iRuralNavigator (MOYBU, SCP) 2016
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
};
export default ResumeCard;
