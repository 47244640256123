import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-scroll";
import Image from "../Common/CloudinaryImage";
import { HeaderContext } from "../../contexts/HeaderContext";
import { Transition } from "@headlessui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import {
    FaGithubAlt,
    FaLinkedin,
    FaServicestack,
    FaHome,
    FaUser,
    FaImages,
    FaBookOpen,
    FaEnvelope,
} from "react-icons/fa";
import "../../styles/Header.css";

const Header = () => {
    const [open, setOpen] = useState(false);

    const { section, setSection } = useContext(HeaderContext);

    useEffect(() => {
        if (section) {
            // console.log(section);
        } else {
            setSection("meCard");
        }
    }, [section, setSection]);

    return (
        <>
            {/* Header */}
            <div className=" lg:w-1/4 mb-5 lg:mb-0 relative">
                {/* Mobile Header */}
                <div className="mobile-header lg:hidden">
                    <div className="flex items-center">
                        {open ? (
                            <button
                                aria-label="Abrir / Cerrar menú"
                                className="mr-3"
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            >
                                <AiOutlineClose className="text-white w-6 h-6" />
                            </button>
                        ) : (
                            <button
                                className="mr-3"
                                aria-label="Abrir / Cerrar menú"
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            >
                                <GiHamburgerMenu className="text-white w-6 h-6" />
                            </button>
                        )}

                        <Image
                            alt="alex llaó garcia"
                            filename="logos/alexllao-favicon"
                            className="rounded-full w-10 mr-3"
                        />

                        <h3 className="text-xl font-extrabold text-white">
                            Àlex Llaó
                            <span className="text-red-500">.</span>
                        </h3>
                    </div>
                </div>

                <Transition
                    className="mobile-header mt-5"
                    show={open}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <div className="mt-5 rounded-lg px-5 py-3 pb-0 text-white lg:hidden duration-500">
                        <ul className="menu">
                            <li
                                className={`${
                                    section === "meCard" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to="meCard"
                                    className={`cursor-pointer block`}
                                    activeClass="active"
                                    smooth={true}
                                    duration={500}
                                    onClick={() => {
                                        setSection("meCard");
                                    }}
                                >
                                    <FaHome className="mr-5 w-4 h-4 inline" />
                                    Inicio
                                </Link>
                            </li>
                            <li
                                className={`${
                                    section === "aboutCard" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to="aboutCard"
                                    className={`cursor-pointer block ${
                                        section === "aboutCard" ? "active" : ""
                                    }`}
                                    activeClass="active"
                                    smooth={"true"}
                                    duration={500}
                                    offset={-50}
                                    onClick={() => {
                                        setSection("aboutCard");
                                    }}
                                >
                                    <FaUser className="mr-5 w-4 h-4 inline" />
                                    Sobre mí
                                </Link>
                            </li>
                            <li
                                className={`${
                                    section === "skillsCard" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to="skillsCard"
                                    className={`cursor-pointer block ${
                                        section === "skillsCard" ? "active" : ""
                                    }`}
                                    activeClass="active"
                                    smooth={"true"}
                                    duration={500}
                                    offset={-50}
                                    onClick={() => {
                                        setSection("skillsCard");
                                    }}
                                >
                                    <FaServicestack className="mr-5 w-4 h-4 inline" />
                                    Habilidades
                                </Link>
                            </li>
                            <li
                                className={`${
                                    section === "resumeCard" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to="resumeCard"
                                    className={`cursor-pointer block ${
                                        section === "resumeCard" ? "active" : ""
                                    }`}
                                    activeClass="active"
                                    smooth={"true"}
                                    duration={500}
                                    offset={-50}
                                    onClick={() => {
                                        setSection("resumeCard");
                                    }}
                                >
                                    <FaBookOpen className="mr-5 w-4 h-4 inline" />
                                    Currículum
                                </Link>
                            </li>
                            <li
                                className={`${
                                    section === "worksCard" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to="worksCard"
                                    className={`cursor-pointer block ${
                                        section === "worksCard" ? "active" : ""
                                    }`}
                                    activeClass="active"
                                    smooth={"true"}
                                    duration={500}
                                    offset={-50}
                                    onClick={() => {
                                        setSection("worksCard");
                                    }}
                                >
                                    <FaImages className="mr-5 w-4 h-4 inline" />
                                    Trabajos
                                </Link>
                            </li>
                            <li
                                className={`${
                                    section === "contactCard" ? "active" : ""
                                } mb-0`}
                            >
                                <Link
                                    to="contactCard"
                                    className={`cursor-pointer block ${
                                        section === "contactCard"
                                            ? "active"
                                            : ""
                                    }`}
                                    activeClass="active"
                                    smooth={"true"}
                                    duration={500}
                                    offset={-50}
                                    onClick={() => {
                                        setSection("contactCard");
                                    }}
                                >
                                    <FaEnvelope className="mr-5 w-4 h-4 inline" />
                                    Contacto
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Transition>

                {/** Desktop Header */}
                <div className="desktop-header hidden lg:p-10 lg:block lg:sticky lg:top-10">
                    <Image
                        alt="alex llaó garcia"
                        filename="logos/alexllao-favicon"
                        className="rounded-full w-20"
                    />

                    <div className="mt-5 text-white">
                        <h3 className="h1 text-2xl font-extrabold">
                            Àlex Llaó
                            <span className="text-red-500">.</span>
                        </h3>
                        <h4 className="uppercase text-xs mt-2">
                            Desarrollador Full-Stack
                        </h4>

                        {/** Nav */}
                        <nav>
                            <ul className="menu">
                                <li
                                    className={`${
                                        section === "meCard" ? "active" : ""
                                    }`}
                                >
                                    <Link
                                        to="meCard"
                                        className={`cursor-pointer block`}
                                        activeClass="active"
                                        smooth={true}
                                        duration={500}
                                        onClick={() => {
                                            setSection("meCard");
                                        }}
                                    >
                                        <FaHome className="mr-5 w-4 h-4 inline" />
                                        Inicio
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        section === "aboutCard" ? "active" : ""
                                    }`}
                                >
                                    <Link
                                        to="aboutCard"
                                        className={`cursor-pointer block ${
                                            section === "aboutCard"
                                                ? "active"
                                                : ""
                                        }`}
                                        activeClass="active"
                                        smooth={"true"}
                                        duration={500}
                                        offset={-50}
                                        onClick={() => {
                                            setSection("aboutCard");
                                        }}
                                    >
                                        <FaUser className="mr-5 w-4 h-4 inline" />
                                        Sobre mí
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        section === "skillsCard" ? "active" : ""
                                    }`}
                                >
                                    <Link
                                        to="skillsCard"
                                        className={`cursor-pointer block ${
                                            section === "skillsCard"
                                                ? "active"
                                                : ""
                                        }`}
                                        activeClass="active"
                                        smooth={"true"}
                                        duration={500}
                                        offset={-50}
                                        onClick={() => {
                                            setSection("skillsCard");
                                        }}
                                    >
                                        <FaServicestack className="mr-5 w-4 h-4 inline" />
                                        Habilidades
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        section === "resumeCard" ? "active" : ""
                                    }`}
                                >
                                    <Link
                                        to="resumeCard"
                                        className={`cursor-pointer block ${
                                            section === "resumeCard"
                                                ? "active"
                                                : ""
                                        }`}
                                        activeClass="active"
                                        smooth={"true"}
                                        duration={500}
                                        offset={-50}
                                        onClick={() => {
                                            setSection("resumeCard");
                                        }}
                                    >
                                        <FaBookOpen className="mr-5 w-4 h-4 inline" />
                                        Currículum
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        section === "worksCard" ? "active" : ""
                                    }`}
                                >
                                    <Link
                                        to="worksCard"
                                        className={`cursor-pointer block ${
                                            section === "worksCard"
                                                ? "active"
                                                : ""
                                        }`}
                                        activeClass="active"
                                        smooth={"true"}
                                        duration={500}
                                        offset={-50}
                                        onClick={() => {
                                            setSection("worksCard");
                                        }}
                                    >
                                        <FaImages className="mr-5 w-4 h-4 inline" />
                                        Trabajos
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        section === "contactCard"
                                            ? "active"
                                            : ""
                                    } mb-0`}
                                >
                                    <Link
                                        to="contactCard"
                                        className={`cursor-pointer block ${
                                            section === "contactCard"
                                                ? "active"
                                                : ""
                                        }`}
                                        activeClass="active"
                                        smooth={"true"}
                                        duration={500}
                                        offset={-50}
                                        onClick={() => {
                                            setSection("contactCard");
                                        }}
                                    >
                                        <FaEnvelope className="mr-5 w-4 h-4 inline" />
                                        Contacto
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        {/** Social */}
                        <div className="flex justify-start mt-10">
                            <a
                                aria-label="Github"
                                href="https://github.com/alexllao"
                                rel="noopener noreferrer"
                                className="mr-5 p-2 rounded-full bg-red-500 hover:bg-red-700 duration-500"
                            >
                                <FaGithubAlt className="w-4 h-4 text-white" />
                            </a>
                            <a
                                aria-label="LinkedIn"
                                href="https://www.linkedin.com/in/alexllao"
                                rel="noopener noreferrer"
                                className="p-2 rounded-full bg-red-500 hover:bg-red-700 duration-500"
                            >
                                <FaLinkedin className="w-4 h-4 text-white" />
                            </a>
                        </div>
                        <div className="text-xs mt-10">
                            &copy; {new Date().getFullYear()} Àlex Llaó
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Header;
