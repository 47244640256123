import React from "react";
import { StaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Image from "gatsby-image";

const GenericImage = props => (
    <StaticQuery
        query={graphql`
            query images {
                allCloudinaryAsset {
                    nodes {
                        fluid {
                            src
                            ...CloudinaryAssetFluid
                        }
                    }
                }
            }
        `}
        render={data => {
            const image = data.allCloudinaryAsset.nodes.find(n => {
                if (n.fluid.src.endsWith(props.filename)) {
                    return n.fluid;
                } else {
                    return null;
                }
            });

            if (!image) {
                return null;
            }

            if (props.children) {
                return (
                    <BackgroundImage
                        fluid={[image.fluid]}
                        className={props.className}
                        fadeIn={true}
                    >
                        {props.children}
                    </BackgroundImage>
                );
            } else {
                return (
                    <Image
                        className={props.className}
                        alt={props.alt || ""}
                        fadeIn={true}
                        fluid={[image.fluid]}
                    />
                );
            }
        }}
    />
);

export default GenericImage;
