import React from "react";

const Footer = () => {
    return (
        <>
            <div className="md:flex md:justify-between default-text">
                <div className="text-gray-600 text-center md:text-left">
                    Copyright &copy; {new Date().getFullYear()} Àlex Llaó Garcia
                </div>
                <div className="text-gray-600 mt-1 md:mt-0 text-center md:text-right">
                    Desarrolado con{" "}
                    <a
                        href="https://www.gatsbyjs.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="default-link"
                    >
                        GatsbyJS
                    </a>{" "}
                    y{" "}
                    <a
                        href="https://tailwindcss.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="default-link"
                    >
                        TailwindCSS
                    </a>
                </div>
            </div>
        </>
    );
};
export default Footer;
