import React from "react";
import Card from "@components/Common/Card";
import Image from "@components/Common/CloudinaryImage";
import "@base/styles/WorksCard.css";

const WorksCard = () => {
    return (
        <>
            <Card title="Trabajos" name="worksCard" className=" worksCard">
                <div className="default-text">
                    Conoce algunos de los proyectos en los que he participado a
                    lo largo de mi carrera profesional.
                </div>

                <div className="mt-10 grid sm:grid-cols-2 gap-10 md:gap-5">
                    {/** EasyPort */}
                    <div className="workCardContainer">
                        <Image
                            filename="projects/easyport"
                            alt="EasyPort"
                            className="h-full bg-red-500"
                        >
                            <div className="workCardContainerInner">
                                <div className="workCardInfo">
                                    <h4 className="h4">EasyPort</h4>
                                    <div className="text">
                                        Guía de navegación donde el usuario
                                        podrá encontrar la mejor información
                                        sobre puertos, servicios relacionados,
                                        mecánicas, restaurantes, etc. Una guía
                                        indispensable para todos los amantes del
                                        mar. Se programó el servicio web, el
                                        gestor de contenidos, web de
                                        presentación y la aplicación en iOS, con
                                        posibilidad de funcionar en modo
                                        offline, para cuando no haya conexión a
                                        la red.
                                    </div>
                                    <a
                                        aria-label="EasyPort"
                                        href="https://www.easyport.es"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="mt-5 default-link text-xs"
                                    >
                                        Más información sobre EasyPort
                                    </a>
                                </div>
                            </div>
                        </Image>
                    </div>

                    {/** EbreBiosfera */}
                    <div className="workCardContainer">
                        <Image
                            filename="projects/ebrebiosfera"
                            alt="Ebrefiosfera"
                            className="h-full bg-red-500"
                        >
                            <div className="workCardContainerInner">
                                <div className="workCardInfo">
                                    <h4 className="h4">Ebrebiosfera</h4>
                                    <div className="text">
                                        En esta aplicación se puede consultar
                                        rutas y activos turísticos de la
                                        Reservera de la Biosfera de les Terres
                                        de l'Ebre. Se programó la aplicación iOS
                                        que se comunica con el CMS oficial para
                                        la actualización de datos. La aplicación
                                        puede ejecutarse de forma offline,
                                        manteniendo en local la información
                                        relativa a los activos y rutas.
                                    </div>
                                    <a
                                        aria-label="EbreBiosfera"
                                        href="https://www.ebrebiosfera.org"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="mt-5 default-link text-xs"
                                    >
                                        Más información sobre EbreBiosfera
                                    </a>
                                </div>
                            </div>
                        </Image>
                    </div>

                    {/** iOpos */}
                    <div className="workCardContainer">
                        <Image
                            filename="projects/igub"
                            alt="EasyPort"
                            className="h-full"
                        >
                            <div className="workCardContainerInner">
                                <div className="workCardInfo">
                                    <h4 className="h4">iOpos</h4>
                                    <div className="text">
                                        Proyecto propio en el que se desarrolló
                                        toda la infraestructura para mantener y
                                        gestionar varias aplicaciones móviles
                                        para la preparación de oposiciones.
                                        Actualmente se gestiona un volumen de
                                        35.000 usuarios activos y más de 1
                                        millón de test corregidos.
                                    </div>
                                    <a
                                        aria-label="iOpos"
                                        href="https://www.iopos.es"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="mt-5 default-link text-xs"
                                    >
                                        Más información sobre iOpos
                                    </a>
                                </div>
                            </div>
                        </Image>
                    </div>

                    {/** iRN */}
                    <div className="workCardContainer">
                        <Image
                            filename="projects/irn"
                            alt="iRuralNavigator"
                            className="h-full bg-red-500"
                        >
                            <div className="workCardContainerInner">
                                <div className="workCardInfo">
                                    <h4 className="h4">iRuralNavigator</h4>
                                    <div className="text">
                                        Aplicación para la localización de
                                        direcciones rurales en toda la
                                        península. Tan solo identificando un
                                        polígono y una parcela el programa
                                        calculará una ruta aproximada hasta la
                                        parcela en concreto. Ideal para
                                        cualquier servicio de ámbito rural de
                                        toda España tales como emergencias,
                                        reparto o asistencia.
                                    </div>
                                    <a
                                        aria-label="iRuralNavigator"
                                        href="https://www.iruralnavigator.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="mt-5 default-link text-xs"
                                    >
                                        Más información sobre iRuralNavigator
                                    </a>
                                </div>
                            </div>
                        </Image>
                    </div>

                    {/** Surtdecasa */}
                    <div className="workCardContainer">
                        <Image
                            filename="projects/surtdecasa"
                            alt="Surtdecasa"
                            className="h-full bg-red-500"
                        >
                            <div className="workCardContainerInner">
                                <div className="workCardInfo">
                                    <h4 className="h4">Surtdecasa</h4>
                                    <div className="text">
                                        Surtdecasa es una plataforma digital de
                                        información de proximidad que da valor a
                                        las actividades de todo el territorio
                                        catalán. Se desarrolló una aplicación
                                        para el sistema operativo iOS que
                                        permite disfrutar de todo el contenido
                                        de la plataforma.
                                    </div>
                                    <a
                                        aria-label="Surtdecasa"
                                        href="https://www.surtdecasa.cat"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="mt-5 default-link text-xs"
                                    >
                                        Más información de Surtdecasa
                                    </a>
                                </div>
                            </div>
                        </Image>
                    </div>
                </div>
            </Card>
        </>
    );
};

export default WorksCard;
